/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
import { CamelCasedPropertiesDeep, ScreamingSnakeCase, SnakeCasedPropertiesDeep, UnionToIntersection } from 'type-fest';
import { snakeCase, camelCase as changeCamelCase } from 'change-case';
import { upperCase } from 'upper-case';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { Ref, ComputedRef, computed } from '@vue/composition-api';
import MobileDetect from 'mobile-detect';
import Jaco from 'jaco';
import { z } from 'zod';

/** 日本の標準時からのタイムゾーンオフセット。 */
const JP_TIMEZONE_OFFSET = 540;

export const isAxiosError = (value: unknown): value is AxiosError<unknown> =>
  typeof value === 'object' && value !== null && 'isAxiosError' in value;

/**
 * 対象のエレメントのページトップからの位置を返します。
 */
export const pageTop = (elem: HTMLElement): number => {
  const parent = elem.offsetParent;
  // エレメントをさかのぼりながら、直上のエレメントに対するy座標位置を加算していきます。
  return (parent instanceof HTMLElement ? pageTop(parent) : 0) + elem.offsetTop;
};

/**
 * Refに対する簡易なセッター関数を生成します。
 */
export const simpleSetValue =
  <T>(ref: Ref<T>) =>
  (setState: T | ((oldState: T) => T)): void => {
    if (typeof setState === 'function') {
      // eslint-disable-next-line no-param-reassign
      ref.value = (setState as (oldState: T) => T)(ref.value);
    } else {
      // eslint-disable-next-line no-param-reassign
      ref.value = setState;
    }
  };

export const requiredRef = <T>(ref: Ref<T>): ComputedRef<Exclude<T, undefined | null>> =>
  computed(() => {
    if (ref.value === undefined || ref.value === null) {
      throw new Error('パラメータが未設定です。');
    }
    return ref.value as Exclude<T, undefined | null>;
  });

export const camelCase = changeCamelCase;

/**
 * 2つのstring literal type を `.` で結合した新しいstring literal typeを返します。
 */
export type Join<T extends string | number, U extends string | number> = T extends '' ? U : `${T}.${U}`;

/**
 * 対象の型に含まれるpathを表すすべてのstring literal typeをUnionとして返します。
 * pathは `.` 区切りです。
 */
export type DeepObjectKeys<T, P extends string | number = ''> =
  Exclude<T, undefined> extends infer TT
    ? keyof TT extends infer U
      ? U extends (number | string) & keyof TT
        ? TT[U] extends Record<string, unknown>
          ? Join<P, U> | DeepObjectKeys<TT[U], Join<P, U>>
          : Join<P, U>
        : never
      : P
    : never;

/**
 * 対象の型の指定したパスに存在する型を取得します。
 */
export type DeepProps<Obj, Path> =
  Exclude<Obj, undefined> extends infer OO
    ? Path extends `${infer H}.${infer T}`
      ? H extends keyof OO
        ? DeepProps<OO[H], T>
        : unknown
      : Path extends keyof OO
        ? OO[Path]
        : unknown
    : unknown;

/**
 * 入力テキストを、大文字スネークケースに変換します。
 *
 * @example
 * ```
 * console.assert( upperSnakeCase('myTestString') === 'MY_TEST_STRING' );
 * console.assert( upperSnakeCase('MyTestString') === 'MY_TEST_STRING' );
 * console.assert( upperSnakeCase('my_test_string') === 'MY_TEST_STRING' );
 * ```
 *
 * @param value
 * @returns
 */
export const upperSnakeCase = <T extends string>(value: T): ScreamingSnakeCase<T> =>
  upperCase(snakeCase(value)) as ScreamingSnakeCase<T>;

type UnionToObjectUnion<X extends string> = X extends string ? { [key in ScreamingSnakeCase<X>]: X } : never;
type UnionToEnumObject<X extends string> = UnionToIntersection<UnionToObjectUnion<X>>;

/**
 * 文字配列を与えると、定数オブジェクトを返す関数。
 * @param args
 * @returns
 */
export const arrayToEnumObject = <T extends string>(args: Readonly<T[]>): UnionToEnumObject<T> =>
  args.reduce(
    (draft, value) => ({ ...draft, [upperCase(snakeCase(value))]: value }),
    {},
  ) as unknown as UnionToEnumObject<T>;

export type KeyTypeOf<T> = keyof T;
export type ValueTypeOf<T> = T[keyof T];

export const keyEnumObject = <T extends Record<string, unknown>>(
  arg: Readonly<T>,
): keyof T extends string ? UnionToEnumObject<keyof T> : never =>
  Object.fromEntries(Object.keys(arg).map((key) => [upperSnakeCase(key), key])) as unknown as keyof T extends string
    ? UnionToEnumObject<keyof T>
    : never;
/**
 * 何も実行しない関数。イベントハンドラの初期値として使用する。
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noOperation = (..._args: unknown[]): void => {};

/**
 * 恒等関数。
 * @returns
 */
export const id = <T>(value: T): T => value;

const charCodeFromTo = (from: string, to: string): number[] => {
  const codeFrom = from.charCodeAt(0);
  const codeTo = to.charCodeAt(0);
  const length = Math.abs(codeTo - codeFrom) + 1;
  const delta = codeTo > codeFrom ? 1 : -1;
  return Array.from({ length }).map((_, index) => codeFrom + index * delta);
};

export const required = <T>(val: T): Required<T> => val as Required<T>;

/**
 * 開始文字と終了文字を指定し、指定範囲の文字コードの文字配列を返します。
 * @param from
 * @param to
 * @returns
 */
export const charFromTo = (from: string, to: string): string[] =>
  charCodeFromTo(from, to).map((ch) => String.fromCharCode(ch));

const azAZ09 = [...charFromTo('a', 'z'), ...charFromTo('A', 'Z'), ...charFromTo('0', '9')];

/**
 * a-z,A-Z,0-9で構成されるランダム文字列を返す。
 * @param length 文字列長。
 * @param options.blackList blacklistに含まれる文字列は返しません。
 * @returns
 */
export const randomString = (length = 10, options?: { blackList?: string[] }): string => {
  const { blackList } = { blackList: [] as string[], ...options };
  let result = '';
  do {
    result = Array.from({ length })
      .map(() => azAZ09[Math.floor(Math.random() * azAZ09.length)])
      .join('');
  } while (blackList.indexOf(result) !== -1);
  return result;
};

const numberChar = /^\d$/;

/**
 * 数値の整数部をカンマ区切りフォーマットする。
 */
export const formatNumber = (value: string | number): string => {
  const inputValueString = typeof value === 'number' ? value.toString() : value;
  const [, flag, intPart, decPart] = /([-+]?)([\d,]+)(?:\.(\d+))?/.exec(inputValueString) || ['', '0'];
  const intPartText = intPart
    .split('')
    .filter((ch) => numberChar.test(ch))
    .join('')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return [flag, intPartText, decPart].join('');
};

/**
 * 電話番号をハイフン区切りフォーマットする。
 * 現状は`XXX-XXXX-XXXX`のみしか対応していないため、必要に応じて拡張すること。
 *
 * @param tel ハイフン区切りなしの11桁の電話番号
 * @returns `XXX-XXXX-XXXX`のハイフン区切りの文字列
 *
 */
export const formatTelNumber = (tel: string): string => tel.replace(/^(.{0,3})(.{0,4})(.{0,4})/, '$1-$2-$3');

/**
 * 日付をフォーマットする。
 * @param value YYYY-MMの文字列
 * @returns YYYY年M月の文字列
 */
export const formatMonth = (value: string): string => {
  const [year, month] = value.split('-');
  return `${year}年${parseInt(month, 10)}月`;
};

export const isNotNil = <T>(value: T): value is Exclude<T, null | undefined> => value !== null && value !== undefined;

/**
 * 入力値が空である可能性を除去する。
 *
 * @param param nullかundefinedの可能性がある値
 * @param defaultValue もしnullやundefinedが入力された場合は、代わりにこの値を設定する。
 * @returns paramをそのまま返す。もしくはdefaultValueを返す。
 * @throws {Error} nullやundefinedが入力された場合、ランタイムエラーが発生する。
 */
export const notNil = <T>(param: T, defaultValue?: Exclude<T, null | undefined>): Exclude<T, null | undefined> => {
  if (param === null || param === undefined) {
    if (defaultValue) {
      return defaultValue;
    }
    throw new Error('default値なしにNilが入力されました。');
  }
  return param as unknown as Exclude<T, null | undefined>;
};

/**
 * 引数のobjectから指定したキーを除去する。
 * @param value
 * @param args
 * @returns
 */
export const omit = <T extends Record<string | number | symbol, unknown>, U extends keyof T>(
  value: T,
  ...args: U[]
): Omit<T, U> => {
  const clone = { ...value };
  args.forEach((key) => delete clone[key]);
  return clone;
};

/**
 * 引数のobjectの指定したキーからなるサブセットを取得する。
 * @param value
 * @param args
 * @returns
 */
export const pick = <T extends Record<string | number | symbol, unknown>, U extends keyof T>(
  value: T,
  ...args: U[]
): Pick<T, U> => {
  const result = {} as Pick<T, U>;
  args.forEach((k) => {
    if (k in value) {
      result[k] = value[k];
    }
  });
  return result;
};

/**
 * 入力値が指定された名前のプロパティを持っているかのみを検証するタイプガード。
 * プリミティブを検証してもラッパーのプロパティは取得できない。
 * @param val
 * @param propNames
 * @returns
 */
export const hasPropNames = <T, N extends string>(val: T, ...propNames: N[]): val is T & Record<N, unknown> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ['object', 'function'].includes(typeof val) && val !== null && propNames.every((pName) => pName in (val as any));

/**
 * 指定時間待機後、値を返します。
 * @param time ミリ秒で指定します。
 * @param value 返却する値です。
 * @returns 第二引数で指定した値を返します。
 */
export const wait = <T>(time = 0, value?: T): Promise<T> =>
  new Promise<T>((resolve) => {
    window.setTimeout(() => resolve(value as T), time);
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ToStatic<T> = T extends (...args: any) => any ? (this: void, ...args: Parameters<T>) => ReturnType<T> : T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ToStaticAll<T extends Record<string | number | symbol, any>> = { [K in keyof T]: ToStatic<T[K]> };

/**
 * axiosのオプション生成ヘルパ。axiosのオプションを静的型チェックすることができる。
 * @param options - 入力は静的型チェックされる。
 * @returns 入力をそのまま返す。
 */
export const axiosOptions = (options: AxiosRequestConfig) => options;

/**
 * 配列からランダムに値を取り出す。
 * @param values
 * @returns
 */
export const randomSample = <T>(values: T[]): T => values[Math.floor(Math.random() * values.length)];

/**
 * 配列の重複を削除して返す。
 * @param values
 * @returns
 */
export const getUniqueArray = <T>(values: T[]): T[] => {
  const uniq = new Set<T>();
  values.forEach((val) => uniq.add(val));
  return Array.from(uniq);
};

/**
 * 入力値側の型チェックのないArray#includes
 *
 * 主にpropsのvalidatorに使用することを想定している
 * @param patterns
 * @returns
 */
export const includes =
  <T>(...patterns: Readonly<T[]>) =>
  (value: unknown): value is T =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patterns.includes(value as T);

/**
 * enumオブジェクトを元に、props validatorを定義する
 *
 * enumオブジェクトを引数として実行すると、propsのvalidatorを返す。
 * 返却されたvalidatorは、enumオブジェクトのvalue値に一致する値が入力された時にtrueを返す。
 */
export const enumValueIncludes = <T extends Record<string | number | symbol, unknown>>(enumObject: T) =>
  includes(...(Object.values(enumObject) as ValueTypeOf<T>[]));

/**
 * 対象が文字列かどうか。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (value: any): value is string => typeof value === 'string';

export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

export const isHtmlInputElement = (value: unknown): value is HTMLInputElement =>
  value !== null && value instanceof HTMLInputElement;

/**
 * カラーコードとして正しいか判定する。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isColorCode = (value: any): boolean =>
  isString(value) && (/#[0-9a-fA-F]{6}/.test(value) || /#[0-9a-fA-F]{3}/.test(value));

/**
 * 対象がURL文字列として正しいか判定する。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUrl = (value: any): boolean => {
  if (isString(value)) {
    try {
      // eslint-disable-next-line no-new
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

type HasPath<T, P extends string> = P extends `${infer P1}.${infer P2}`
  ? T & { [K in P1]: HasPath<K extends keyof T ? Required<T>[K] : unknown, P2> }
  : T & { [K in P]: K extends keyof T ? Required<T>[K] : unknown };

/**
 * 対象オブジェクトに指定したパスが存在するかチェックするTypeGuard。
 * @param value - 検証される値。
 * @param path - ドット区切りでpathを指定する。
 * @returns
 */
export const hasPath = <T, P extends string>(value: T, path: P): value is HasPath<T, P> => {
  const pathArray = path.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let v: any = value;
  return pathArray.every((p) => {
    if (v !== null && typeof v === 'object' && p in v) {
      v = v[p];
      return true;
    }
    return false;
  });
};

type HasProps<T extends string, U> = T extends `${infer Head}.${infer Tail}`
  ? { [K in Head]: HasProps<Tail, U> }
  : { [K in T]: U };

type Guard<T> = (value: unknown) => value is T;
type GuardType<T extends Guard<unknown>> = T extends Guard<infer U> ? U : never;

export const hasProps = <P extends string, U extends Guard<unknown>>(
  value: unknown,
  path: P,
  guard: U,
): value is HasProps<P, GuardType<U>> => {
  const target = path.split('.').reduce(
    (summary, key) => {
      const { hasKey, props } = summary;
      return hasKey &&
        value !== null &&
        value !== undefined &&
        typeof value === 'object' &&
        key in (props as Record<string, unknown>)
        ? { hasKey: true, props: (props as Record<string, unknown>)[key] }
        : { hasKey: false, props: null };
    },
    { hasKey: true, props: value },
  );
  return target.hasKey && guard(target.props);
};

export const isStr = (v: unknown): v is string => typeof v === 'string';
export const isNum = (v: unknown): v is number => typeof v === 'number';

type GetValueByPath = <T, P extends DeepObjectKeys<T>>(
  value: T,
  path: P,
  defaultValue?: DeepProps<T, P>,
) => DeepProps<T, P>;

/**
 * 対象オブジェクトに指定したパスの値を取得する。対象がない場合はデフォルト値を返す。
 * テンプレートの中では `?.` が使えないためこれを使用する。
 * @param value - 検証される値。
 * @param path - ドット区切りでpathを指定する。
 * @param defaultValue - 対象がない場合に返却するデフォルト値。
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueByPath: GetValueByPath = (...params: unknown[]) => {
  const [value, path, defaultValue] = params;
  const pathArray = (path as string).split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = value;
  // eslint-disable-next-line no-restricted-syntax
  for (const p of pathArray) {
    if (result !== null && typeof result === 'object' && p in result) {
      result = result[p];
    } else if (params.length === 3) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return defaultValue as any;
    } else {
      throw new Error('データがありません。');
    }
  }
  return result;
};

/**
 * 対象文字列の行頭にインデントをつけます。
 * @param value インデントをつけたい文字列です。
 * @param num デフォルトのインデントは半角スペース4つです。
 * @returns インデントがついたvalueを返します。
 */
export const indent = (value: string, num = 4) => {
  const spacer = Array.from({ length: num }).fill(' ').join('');
  return value.replace(/^/gm, spacer);
};

/**
 * Refから必須パラメータを取り出すときに使用します。
 *
 * 必須パラメータがない場合、わかりやすい適切なエラーメッセージを生成します。
 * @param target - `{modalParmeter}` のように引数を与えます。キーはエラーログ用に使用されます。
 * @param path - ドット区切りのパスを指定します。
 * @returns 対象のパスにデータがあればそれを返します。
 * @throws 指定したパスにデータが存在しない場合例外となります。
 *
 * @example
 * const value = ref({
 *   test1: {
 *     test2: 123,
 *   },
 * });
 * try {
 *   // 値を取り出す対象は{}括弧で囲みます。
 *   getRequiredRefValueByPath({value}, 'test1.xxxx');
 * } catch (e) {
 *   // e.messagesには以下のようなわかりやすいエラーが入ります。
 *   // `必須データが設定されていません: value.test1.xxxx`;
 *   console.log(e.message)
 * }
 */
export const getRequiredRefValueByPath = <T, U extends string>(
  target: Record<string, Ref<T>>,
  path: U,
): U extends '' ? T : Exclude<DeepProps<T, U>, undefined> => {
  const entries = Object.entries(target);
  if (entries.length !== 1) {
    throw new Error('実装エラー');
  }
  const [[objName, { value }]] = entries;
  if (path === '') {
    if (value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return value as any;
    }
    throw new Error([`必須データが設定されていません: ${objName}`].join(`\n`));
  }
  const result = path.split('.').reduce((val, segment) => {
    if (segment in val) {
      return val[segment];
    }
    return undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, value as any);

  if (result === undefined) {
    throw new Error(
      [`必須データが設定されていません: ${objName}.${path}`, indent(`${JSON.stringify(value, null, 2)}`)].join(`\n`),
    );
  }
  return result;
};

/**
 * RefにObjectを指定していて、そこから値を取り出す時に使用する。
 * 指定したRef内のオブジェクトのパスの値を取得する。対象がない場合はデフォルト値を返す。
 * テンプレートの中では `?.` が使えないためこれを使用する。
 * @param value - 検証される値。
 * @param path - ドット区切りでpathを指定する。
 * @param defaultValue - 対象がない場合に返却するデフォルト値。
 * @returns
 */
type GetRefDeep = <T extends Ref<unknown>, P extends DeepObjectKeys<Exclude<T['value'], undefined>> & string>(
  value: T,
  path: P,
  defaultValue?: DeepProps<T, `value.${P}`>,
) => Exclude<DeepProps<T, `value.${typeof path}`>, undefined>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRefDeep: GetRefDeep = (...params: any[]) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  (getValueByPath as any)(params[0].value, ...params.slice(1));

type BemModifierRecord =
  | string
  | Record<string, string | number | boolean | undefined | null>
  | (string | Record<string, string | number | boolean | undefined | null>)[];

/**
 * エレメントに付与するBEM classの配列を生成します。
 *
 * クラス生成の有名ユーティリティである、clsxのような記述方法で、classの記述を行うためのユーティリティです。
 * @param groupAndElement - GroupNameもしくはGroupName__ElementNameを文字列で指定する。
 * @param modifiers - modifierを指定する。
 * @returns BEMClassの配列です。
 *
 * @example
 * const hasMod4 = true;
 * const hasMod5 = false;
 * const mod6Value = 'value1'
 *
 * const classes = bemx(
 *   // 第1引数では、Group, もしくはGroup__Elementを指定。
 *   'Group__Element',
 *   // 第2引数以下で付与するModifierを列挙してゆきます。
 *   'mod1',
 *   // 文字配列で追加することが可能。
 *   ['mod2', 'mod3'],
 *   // オブジェクト形式でvalueをbooleanにした場合、boolean値でつけ外し可能
 *   {
 *     mod4: hasMod4,
 *     mod5: hasMod5,
 *   },
 *   {
 *     オブジェクト形式でvalueをstringにした場合、--key-value形式のmodifierの付与が可能。
 *     mod6: mod6Value,
 *     // 数値は文字列のように出力される。
 *     mod7: 0,
 *     // 空文字やundefinedが指定された場合は対象のmodifierはつかない。
 *     mod8: '',
 *     mod9: undefined
 *   }
 * );
 * // // この時
 * // classes = [
 * //   'Group__Element',
 * //   'Group__Element--mod1',
 * //   'Group__Element--mod2',
 * //   'Group__Element--mod3',
 * //   'Group__Element--mod4',
 * //   'Group__Element--mod6-value1',
 * //   'Group__Element--mod7-0',
 * // ];
 * // // となる。
 * // - ModifierのつかないGroup__Elementが先頭に出力される。
 * // - falsyな値が設定された、mod5, mod8, mod9は出力されない。
 */
export const bemx = (groupAndElement: string, ...modifiers: BemModifierRecord[]): string[] => [
  groupAndElement,
  ...modifiers
    .flat()
    .flatMap((v1) => {
      if (typeof v1 === 'string') {
        return v1 ? [v1] : [];
      }
      if (typeof v1 === 'object') {
        return Object.entries(v1).flatMap(([k, v]) => {
          if ((typeof v === 'string' && v.length > 0) || typeof v === 'number') {
            return [`${k}-${v}`];
          }
          if (v) {
            return [`${k}`];
          }
          return [];
        });
      }
      return [v1];
    })
    .map((m) => `${groupAndElement}--${m}`),
];

/**
 * symbol含んだオブジェクトの全てのキーを返す。
 */
export const getAllKeys = <T>(value: T): (keyof T)[] =>
  typeof value === 'object' && value !== null
    ? ([
        ...Object.keys(value), //
        ...Object.getOwnPropertySymbols(value),
      ] as (keyof typeof value)[])
    : [];

/**
 * オブジェクトを階層的にコピーします。
 * @param value
 * @returns
 */
export const cloneDeep = <T>(value: T): T => {
  if (value === null) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((v) => cloneDeep(v) as unknown) as unknown as T;
  }
  if (typeof value === 'object') {
    return Object.fromEntries(getAllKeys(value).map((key) => [key, cloneDeep(value?.[key])])) as unknown as T;
  }
  return value;
};

/**
 * ターゲットの指定したパスに存在するデータを更新します。
 *
 * この関数はターゲットに対し破壊的に作用します。
 * 事前にcloneDeepなどで複製をとるようにしてください。
 */
export const updateWithPath = <T, U extends string>(target: T, path: U, value: DeepProps<T, U>): void => {
  const init = path.split('.');
  const last = init.pop();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const t = init.reduce((obj, p) => obj?.[p], target as any);
  if (typeof t === 'object' && typeof last === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (t as unknown as any)[last] = value;
  }
};

/**
 * 対象のオブジェクトのキーを再起的にcamelCaseに変換します。
 * @param value
 * @returns
 */
export const camelCaseDeep = <T>(value: T): CamelCasedPropertiesDeep<T> => {
  if (Array.isArray(value)) {
    return value.map((v) => camelCaseDeep(v) as never) as never;
  }
  if (value !== null && typeof value === 'object') {
    return Object.fromEntries(Object.entries(value).map(([k, v]) => [camelCase(k), camelCaseDeep(v)])) as never;
  }
  return value as never;
};

/**
 * 対象のオブジェクトのキーを再起的にsnake_caseに変換します。
 * @param value
 * @returns
 */
export const snakeCaseDeep = <T>(value: T): SnakeCasedPropertiesDeep<T> => {
  if (Array.isArray(value)) {
    return value.map((v) => snakeCaseDeep(v) as never) as never;
  }
  if (value !== null && typeof value === 'object') {
    return Object.fromEntries(Object.entries(value).map(([k, v]) => [snakeCase(k), snakeCaseDeep(v)])) as never;
  }
  return value as never;
};

/**
 * Record型を判定するタイプガード。
 */
export const isRecord = (v: unknown): v is Record<string | number | symbol, unknown> =>
  v !== null && typeof v === 'object' && !Array.isArray(v);

/**
 * 対象が同値かどうかを比較する関数。
 *
 * 簡易な実装なので再起的なオブジェクトに使用した場合、ループにハマる可能性がある。使い所を考えること。
 * @param a
 * @param b
 * @returns
 */
export const deepEqual = (a: unknown, b: unknown): boolean => {
  if (a === b) {
    // primitiveの同値か、同一インスタンスの場合。
    return true;
  }
  if (Number.isNaN(a) && Number.isNaN(b)) {
    // NaNの場合は上から漏れるため別途追加。
    return true;
  }
  if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
    // 異なるオブジェクトインスタンスの比較。
    if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
      // 配列同士の場合中身の全てが一致すること。
      return a.every((elemA, idx) => deepEqual(elemA, b[idx]));
    }
    if (isRecord(a) && isRecord(b)) {
      // object同士の比較。
      const aKeys = getAllKeys(a);
      const bKeys = getAllKeys(b);
      return (
        aKeys.length === bKeys.length &&
        aKeys.every((aKey) => bKeys.includes(aKey)) &&
        aKeys.every((aKey) => deepEqual(a[aKey], b[aKey]))
      );
    }
    // それ以外のObjectの組み合わせは不一致とする。
    return false;
  }
  return false;
};

/**
 * システムロケールに関わらず現在の日本の日付を取得します。
 * @returns YYYY-MM-DD形式の現在日付。
 */
export const getToDayJp = () => {
  const today = new Date();
  // UTCDateを取得したときに日本時刻になるようにオフセット分を加算する。
  today.setMinutes(today.getMinutes() + JP_TIMEZONE_OFFSET);
  return [
    today.getUTCFullYear(),
    (today.getUTCMonth() + 1).toString(10).padStart(2, '0'),
    today.getUTCDate().toString(10).padStart(2, '0'),
  ].join('-');
};

/**
 * 対象の文字列が正しい日付かどうか検証します。
 *
 * @param input YYYY-MM-DD形式の文字列。
 */
export const isValidDate = (input: string): boolean =>
  typeof input === 'string' &&
  /^\d{4}-\d{2}-\d{2}/.test(input) &&
  new Date(`${input}T00:00:00Z`).toISOString().startsWith(input);

/**
 * 誕生日を引数にとり、未成年かどうかを返す。
 * ブラウザの時刻をもとに計算し、異なるタイムゾーンで実行されても期待通り動作する。
 * @returns
 */
export const isMinor = (input: string): boolean => {
  const today = new Date();
  // UTCDateを取得したときに日本時刻になるようにオフセット分を加算する。
  today.setMinutes(today.getMinutes() + JP_TIMEZONE_OFFSET);
  const minorDateString = [
    today.getUTCFullYear() - 18,
    (today.getUTCMonth() + 1).toString(10).padStart(2, '0'),
    today.getUTCDate().toString(10).padStart(2, '0'),
  ].join('-');
  // 日付文字列をUnixTimeに変換し、比較、返却。parse
  return Date.parse(`${input}T00:00:00+09:00`) > Date.parse(`${minorDateString}T00:00:00+09:00`);
};

/**
 * 様々なハイフンを全角ハイフンに変換する。
 */
export const convertHyphen = (value: string, hankaku?: boolean) =>
  value.replace(/ー|-|﹣|－|­|‐|‑|⁃|˗|−|‒|–|—|―|─|━|╴|╶|╸|╺|╼|╾/g, hankaku ? '-' : 'ー');

/**
 * 対象の文字列を全角に変換する。
 */
export const toZenkaku = (value: string) => new Jaco(convertHyphen(value)).toWide().combinateSoundMarks().toString();

/**
 * 対象の文字列を全角ひらがなにする。
 */
export const toZenkakuHiragana = (value: string) => new Jaco(convertHyphen(value)).toWide().toHiragana(true).toString();

/**
 * 対象の文字列を半角数字にする。
 *
 * 数時以外は消し飛ばす。
 */
export const toHankakuNumber = (value: string) =>
  new Jaco(value).toNarrowAlphanumeric().toString().replace(/[^\d]/g, '');

/**
 * 対象の文字を半角にする。
 *
 * 主にemailの入力に使用するために用意した。\
 * emailアドレスはascii文字列であると定められているため、数、記号、アルファベットは半角にするが、ひらがな、カタカナとも半角カナに変換する。\
 * 半角カナはasciiではないため、当然、emailのバリデーションをパスすることはないが、挙動として半角になると言う動作がわかりやすいため
 * 現在はそのように実装している。
 */
export const toHankaku = (value: string) => new Jaco(value).toNarrowKatakana(true).toNarrow(true).toString();

/**
 * switch文の網羅チェッカです。
 *
 * defaultに仕掛けることで、switch文が網羅されているかどうかをチェックできます。
 * チェック漏れに対して例外を吐きません。例外を吐きたい場合は、 `mustToBeNever` 関数を使用してください。
 *
 * @example
 * const x: 'a' | 'b' | 'c' = ...;
 * switch (x) {
 *   case 'a': {
 *     break;
 *   }
 *   default: {
 *     // case 'b', case 'c' が判定されていないため
 *     // xの型は'b' | 'c'となりneverに適合しない。型エラーが発生する。
 *     caseCompleted(x);
 *   }
 * }
 */
export const caseCompleted = (_value: never): void => {};

/**
 * switch文の網羅チェッカです。
 *
 * `caseCompleted` 関数の型チェックに加えて、ケース漏れに対して実行時エラーをthrowします。
 * @param value
 */
export const mustToBeNever = (_value: never): void => {
  throw new Error('不正な入力値です。');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNothing = (value: any): value is null | undefined | '' => value === null || value === undefined || value === '';

// normalizeParameter用

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toRequiredString = (value: any): string => {
  if (!isNothing(value) && (typeof value === 'string' || typeof value === 'number')) {
    return `${value}`;
  }
  throw new Error('');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toOptionalString = (value: any): string | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return `${value}`;
  }
  throw new Error('');
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toOptionalNumber = (value: any): number | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  if (typeof value === 'string') {
    return Number.parseInt(value, 10);
  }
  if (typeof value === 'number') {
    return value;
  }
  throw new Error('');
};

export const toNumberEnumAllowEmpty =
  <T extends number[]>(...patterns: Readonly<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    if (typeof value === 'number' && patterns.includes(value)) {
      return value as unknown as T[number];
    }
    if (typeof value === 'string' && /^[\d]+$/.test(value) && patterns.includes(Number.parseInt(value, 10))) {
      return Number.parseInt(value, 10) as unknown as T[number];
    }
    if (!value) {
      return value as unknown as T;
    }
    throw Error('');
  };

export const toNumberEnum =
  <T extends number[]>(...patterns: Readonly<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    const result = toNumberEnumAllowEmpty(...patterns)(value);
    if (!includes(patterns)(result)) {
      throw Error('');
    }
    return result;
  };

export const toNumericStringEnum =
  <T extends string[]>(...patterns: Readonly<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    const mapper = Object.fromEntries(
      patterns.map((v) => {
        if (/^\d+$/.test(v)) {
          return [Number.parseInt(v, 10), v];
        }
        throw Error('');
      }),
    );
    if (typeof value === 'string' && /^\d+$/.test(value) && Number.parseInt(value, 10) in mapper) {
      return mapper[Number.parseInt(value, 10)] as unknown as T[number];
    }
    if (typeof value === 'number' && value in mapper) {
      return mapper[value] as unknown as T[number];
    }
    throw Error('');
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toOptionalBoolean = (value: any): boolean | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return value !== 0;
  }
  if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        throw new Error('');
    }
  }
  throw new Error('');
};

export const toNumberEnumSet =
  <T extends number[]>(...patterns: Readonly<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (values: any) => {
    const result: number[] = [];
    if (isNothing(values)) {
      return [] as T[number][];
    }
    if (Array.isArray(values)) {
      values.forEach((v) => {
        if (typeof v === 'number' && patterns.includes(v)) {
          result.push(v);
        } else if (typeof v === 'string' && /^[\d]+$/.test(v) && patterns.includes(Number.parseInt(v, 10))) {
          result.push(Number.parseInt(v, 10));
        } else {
          throw new Error('');
        }
      });
    } else if (typeof values === 'number' && patterns.includes(values)) {
      result.push(values);
    } else if (typeof values === 'string' && /^[\d]+$/.test(values) && patterns.includes(Number.parseInt(values, 10))) {
      result.push(Number.parseInt(values, 10));
    } else if (values !== undefined || values !== null) {
      // 上記変換パターンに属せず、undefinedでもnullでもなければ例外。
      throw new Error('');
    }
    return result as T[number][];
  };

export const toNumericStringEnumSet =
  <T extends string[]>(...patterns: Readonly<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (values: any) => {
    const mapper = Object.fromEntries(
      patterns.map((v) => {
        if (/^\d+$/.test(v)) {
          return [Number.parseInt(v, 10), v];
        }
        throw Error('');
      }),
    );
    const results: string[] = [];
    if (Array.isArray(values)) {
      values.forEach((value) => {
        if (typeof value === 'string' && /^\d+$/.test(value) && Number.parseInt(value, 10) in mapper) {
          results.push(mapper[Number.parseInt(value, 10)] as unknown as T[number]);
        } else if (typeof value === 'number' && value in mapper) {
          results.push(mapper[value]);
        } else {
          throw Error('');
        }
      });
    } else if (typeof values === 'number') {
      const value = mapper[values];
      if (value) {
        results.push(value);
      } else {
        throw Error('');
      }
    } else if (typeof values === 'string' && /^[\d]+$/.test(values)) {
      const value = mapper[Number.parseInt(values, 10)];
      if (value) {
        results.push(value);
      } else {
        throw Error('');
      }
    } else if (values !== undefined || values !== null) {
      // 上記変換パターンに属せず、undefinedでもnullでもなければ例外。
      throw new Error('');
    }
    return results as T[number][];
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toDecimal = (value: any): number => {
  if (typeof value === 'string' && /^[\d,]*\d$/.test(value)) {
    return Number.parseInt(value.replaceAll(',', ''), 10);
  }
  if (typeof value === 'number' && Math.floor(value) === value) {
    return value;
  }
  throw new Error('');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toOptionalDecimal = (value: any): number | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  return toDecimal(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toDateString = (value: any): string => {
  if (typeof value === 'string' && /^\d{4}-\d{1,2}-\d{1,2}$/) {
    const dateStr = new Date(value)?.toJSON()?.substring?.(0, 10);
    if (typeof dateStr === 'string') {
      return dateStr;
    }
  }
  throw new Error('');
};

export const toOptionalDateString = (value: unknown): string | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  return toDateString(value);
};

export const toOptionalStringArray = (value: unknown): string[] | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return [`${value}`];
  }
  if (Array.isArray(value)) {
    return value.map((v) => {
      if (typeof v === 'string' || (typeof v === 'number' && !Number.isNaN(v))) {
        return `${v}`;
      }
      throw new Error('');
    });
  }
  throw new Error('');
};

export const toOptionalString2DArray = (value: unknown): string[][] | undefined => {
  if (isNothing(value)) {
    return undefined;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return [[`${value}`]];
  }
  if (Array.isArray(value)) {
    return value.map((v) => toOptionalStringArray(v)).flatMap((v) => (v ? [v] : []));
  }
  throw new Error('');
};

/**
 * `0` - `16` の入力数値を `'0'` - `'f'` の文字列に変換します。
 */
export const numToHex = Object.fromEntries(
  [...charFromTo('0', '9'), ...charFromTo('a', 'f')].map((v) => [Number.parseInt(v, 16), v]),
);

/**
 * uuid形式のランダム文字列を生成します。
 *
 * アルファベットは小文字です。
 */
export const uuid = () => {
  const uint8Array = new Uint8Array(16);
  window.crypto.getRandomValues(uint8Array);
  const randomArray = Array.from(uint8Array).flatMap((v) => [numToHex[v & 15], numToHex[v >> 4]]);
  return [
    randomArray.splice(0, 8),
    randomArray.splice(0, 4),
    randomArray.splice(0, 4),
    randomArray.splice(0, 4),
    randomArray.splice(0, 12),
  ]
    .map((v) => v.join(''))
    .join('-');
};

/**
 * 新しいタブで指定したURLを開きます。
 * ボタンクリック等で開く場合に利用。
 * リンクの場合は、AppLinkコンポーネントを使ってください。
 *
 */
export const windowOpenNewTab = (url: string) => {
  window.open(
    url,
    '_blank',
    [
      'noopener', // タブ、またはウィンドウを乗っ取られないようにする。他人の管理サイトを開く場合はセキュリティ的に絶対必要。
      'noreferrer', // リファラを渡さない。
    ].join(','),
  );
};

/**
 * 非同期処理の簡易リトライ制御ユーティリティ。
 */
export const asyncRetryUtil = async <T>(param: {
  /**
   * リトライ実行対象の非同期処理です。
   */
  process: () => Promise<T>;

  /**
   * リトライ回数を指定
   * もしくはカウントをとって、リトライ動作までの待ち時間を返す関数をセットします。
   * 関数はnumberを返す限りリトライし続けるので、適当に打ち切ること。
   */
  retry: number | ((count: number) => number | void);

  /**
   * オプションです。
   *
   * 発生した例外を判定し、リトライするべきかどうか決定する関数をセットします。
   */
  retryTarget?: (err: unknown) => boolean;
}): Promise<T> => {
  let count = 0;
  const { retry } = param;
  const retryFunc = typeof retry === 'number' ? (_cnt: number) => (count <= retry ? 1000 * count : undefined) : retry;
  const retryConditionFunc = param.retryTarget ?? ((_err: unknown) => true);

  let loop = true;
  // eslint-disable-next-line no-constant-condition
  while (loop) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await param.process();
      loop = false;
      return result;
    } catch (e) {
      count += 1;
      if (retryConditionFunc(e)) {
        const waitMilliSecond = retryFunc(count);
        if (typeof waitMilliSecond === 'number') {
          // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-loop-func
          void (await new Promise((resolve) => {
            window.setTimeout(resolve, waitMilliSecond);
          }));
        } else {
          loop = false;
          throw e;
        }
      } else {
        loop = false;
        throw e;
      }
    }
  }
  throw Error('');
};

/**
 * MessageChannelを生成するファクトリ関数です。
 * テスト時にインスタンス生成の差し替えを行うためにある。
 * @returns
 */
export const createChannel = () => new window.MessageChannel();

const md = () => new MobileDetect(window.navigator.userAgent);

/**
 * モバイル判定（phone or tablet）
 * @returns
 */
export const isMobile = () => !!md().mobile();

/**
 * デスクトップ判定
 * @returns
 */
export const isDesktop = () => !md().mobile();

/**
 * スマートフォン判定
 * @returns
 */
export const isPhone = () => !!md().phone();

/**
 * タブレット判定
 * @returns
 */
export const isTablet = () => !!md().tablet();

/**
 * iOS判定（iPhone or iPad）
 * @returns
 */
export const isIOS = (): boolean => md().os() === 'iOS' || md().os() === 'iPadOS';

/**
 * AndroidOS判定
 * @returns
 */
export const isAndroidOS = (): boolean => md().os() === 'AndroidOS';

export const deferred = <T>(): Readonly<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [promise: Promise<T>, resolve: (result: T) => void, reject: (error?: any) => void, abort: () => void]
> => {
  const handler = { resolve: (_result: T) => {}, reject: (_error: Error) => {} };
  const abortController = new AbortController();
  const abort = () => {
    abortController.abort();
  };
  const onAbort = () => {
    handler.reject(new Error('AbortError'));
  };
  abortController.signal.addEventListener('abort', onAbort);
  const promise = new Promise<T>((resolve, reject) => {
    Object.assign(handler, { resolve, reject });
  });
  return [promise, handler.resolve, handler.reject, abort];
};

/**
 * dataURI形式のSVG内部の色を変更する。
 *
 * @param svg data-uri形式のsvgテキスト。encodeはutf8形式。base64でないことに注意。
 * @param mapper カラーコードのマッパー。変更対象のカラーコードをキーに設定し、変更後のカラーコードをvalueに設定したオブジェクトを渡す。
 */
export const replaceSvgColor = (svg: string, mapper: Record<string, string>): string => {
  const [, scheme, encodedValue] = /^(data:image\/svg\+xml[^,]*),(.*)/.exec(svg) ?? ['', '', ''];
  // dom操作でsvgの属性値を変更するためにダミーのdivエレメントを生成し、svgをchildNodeに配置する。
  const container = document.createElement('div');
  container.innerHTML = decodeURIComponent(encodedValue);

  // domノードをselect、トラバースして、属性ノード内のカラーコードを差し替える。
  Object.keys(mapper).forEach((key) => {
    const replaceColor = mapper[key];
    // 単純なfillとstrokeのみに対応している。他にも必要であれば増やしていく。
    container.querySelectorAll(`[stroke="${key}"]`).forEach((node) => {
      node.setAttribute('stroke', replaceColor);
    });
    container.querySelectorAll(`[fill="${key}"]`).forEach((node) => {
      node.setAttribute('fill', replaceColor);
    });
  });

  // 再度data-uriに変換し直し、戻り値として返却する。
  return [scheme, encodeURIComponent(container.innerHTML)].join(',');
};

/**
 * UNION型をCOND条件で絞り込みます。
 * ジェネリックなタイプガードの戻り値型として利用できます。
 */
export type Filter<
  UNION extends Record<string | number | symbol, unknown>,
  COND extends Record<string | number | symbol, unknown>,
> = UNION extends COND ? UNION : never;

// 型の置換
export type TypeReplace<T, U> = {
  [key in keyof T]: key extends keyof U ? U[key] : T[key];
};

/**
 * 対象のZodスキーマからZodOptionalを剥がす。
 */
export type ZodUnwrap<T extends z.ZodTypeAny> =
  T extends z.ZodPromise<infer Q> ? Q : T extends z.ZodDefault<infer R> ? R : T extends z.ZodOptional<infer S> ? S : T;

/**
 * ZodType#safeParseの結果型からPromiseを剥がす。
 */
export type UnPromiseSafeParseReturnType<T> =
  T extends z.SafeParseReturnType<infer X, infer Y> ? z.SafeParseReturnType<Awaited<X>, Awaited<Y>> : T;

/**
 * オブジェクトの余剰プロパティチェックを外す。
 */
type NonStrictObject<T extends Record<string | symbol | number, unknown>> = {
  [K in keyof T]: NonStrict<T[K]>;
} & Record<string | symbol | number, unknown>;

/**
 * タプル型のメンバーの余剰プロパティチェックを外す。
 */
type NonStrictMemberTuple<T extends [any, ...any[]] | []> = // eslint-disable-line @typescript-eslint/no-explicit-any
  T extends [infer U, ...infer V extends [any, ...any[]]] // eslint-disable-line @typescript-eslint/no-explicit-any
    ? [NonStrict<U>, ...NonStrictMemberTuple<V>]
    : T extends [infer U]
      ? [NonStrict<U>]
      : [];

/**
 * 余剰プロパティチェックを外した型を得る。
 *
 * ツリー構造を潜りながら再帰的に型を計算します。
 */
export type NonStrict<T> = T extends [unknown, ...unknown[]]
  ? NonStrictMemberTuple<T> // タプル
  : T extends (infer U)[]
    ? NonStrict<U>[] // 配列
    : T extends Record<string | symbol | number, unknown>
      ? NonStrictObject<T> // Object
      : T; // その他

/**
 * 対象のzod schemaからoptional,default,promiseを剥がします。
 * @param zodType
 * @returns
 */
export const zodUnwrap = <
  T extends z.ZodType | z.ZodOptional<z.ZodType> | z.ZodDefault<z.ZodType> | z.ZodPromise<z.ZodType>,
>(
  zodType: T,
): ZodUnwrap<T> => {
  if ('removeDefault' in zodType && typeof zodType.removeDefault === 'function' && 'default' in zodType) {
    return zodType.removeDefault() as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  if ('unwrap' in zodType) {
    return zodType.unwrap() as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  return zodType as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * ZodErrorをエラーメッセージに変換します。
 * @param issues
 * @returns
 */
export const zodErrorToMessage = (zodError: z.ZodError) => {
  const messages: string[] = [];
  zodError.issues.forEach((issue) => {
    const paths = issue.path ?? [];
    if (paths.length > 0) {
      const path = paths
        .map((v) => (typeof v === 'string' ? `.${v}` : `[${v}]`))
        .join('')
        .replace(/^\./, '');
      messages.push(`${path}: ${issue.code} - ${issue.message}`);
    } else {
      messages.push(`${issue.code} - ${issue.message}`);
    }
  });
  return messages.join('\n');
};

/**
 * throwされた不明なデータをJSON文字列に変換します。
 * @param e
 * @returns
 */
export const errorToString = (e: unknown): string => {
  if (e) {
    try {
      return JSON.stringify(e, null, 2);
    } catch {
      /* */
    }
    try {
      return String(e);
    } catch {
      /* */
    }
    // 文字列にできない！！！
    return '不明なエラー';
  }
  return 'エラーメッセージなし（不明なエラー）';
};

/**
 * 一つのPOST_APIを読んでエラーレスポンスをもらっても無視します。
 * @param url
 * @param body
 * @returns
 */
export const callLogPostAPINotRaiseErr = (body: string): void => {
  void (async () => {
    try {
      await window.fetch(`${process.env.VUE_APP_ATONE_ENDPOINT}/atone/javascript/payment/pre_processing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: body,
        }),
      });
    } catch (e) {
      /* 握りつぶす */
    }
  })();
};

/**
 * start()までにおこなったconfig系の関数呼び出し情報のオブジェクト
 */
export type OperationParam = {
  modal_type: string; // 'Np' | 'Atone' | 'AtoneRegister'
  name: string; // 'config' | 'merge' | 'start'
  raw_data: string;
};
