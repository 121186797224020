import { DeepOmit } from 'deep-utility-types';
import { z } from 'zod';
import { fromZodIssue } from 'zod-validation-error';
import { ERROR_TYPE, ServiceType } from '@services/ModalParameterContext';
import { AtoneSettlementServiceInputType } from '@schemas/atone-settlement/AtoneSettlementParameterSchema';
import { AtoneLiteSettlementServiceInputType } from '@schemas/atone-lite-settlement/AtoneLiteSettlementParameterSchema';
import { AtoneLiteAuthServiceInputType } from '@schemas/atone-lite-auth/AtoneLiteAuthParameterSchema';
import { NpAtobaraiSettlementServiceInputType } from '@schemas/np-atobarai-settlement/NpAtobaraiSettlementParameterSchema';
import { atoneSettlementCallbackDefinition } from '@schemas/atone-settlement/AtoneSettlementCallbackSchema';
import { atoneAuthCallbackDefinition } from '@schemas/atone-auth/AtoneAuthCallbackSchema';
import { AtoneAuthServiceInputType } from '@schemas/atone-auth/AtoneAuthParameterSchema';
import { atoneLiteSettlementCallbackDefinition } from '@schemas/atone-lite-settlement/AtoneLiteSettlementCallbackSchema';
import { atoneLiteAuthCallbackDefinition } from '@schemas/atone-lite-auth/AtoneLiteAuthCallbackSchema';
import { npAtobaraiSettlementCallbackDefinition } from '@schemas/np-atobarai-settlement/NpAtobaraiSettlementCallbackSchema';
import { pick, callLogPostAPINotRaiseErr, OperationParam } from '@berlin-front/common/utils';
import AtoneModule from '@np/Atone';
import type commonParamAdditionalSpace from '@schemas/common-schemas/common-param-additional-space';
import { useSessionId } from '@berlin-front/common/composables/useSessionId';
import ModalDriver from './ModalDriver';

type AdditionalSpaceParam = typeof commonParamAdditionalSpace._input;

/**
 * Np.configのサービスパラメータ - atone翌月後払い決済
 *
 * パラメータ型とコールバック型は別でスキーマ定義しているため、それらをマージしてる。
 */
type NpAtoneSettlementServiceInputType = DeepOmit<
  AtoneSettlementServiceInputType,
  // is_legacyは非公開。使用するモジュールによって切り替えるため。
  | 'is_legacy'
  // 以下旧atoneのoptionは使用可能だが、deprecatedなのでインターフェイス上からは除外。
  | 'payment.description_trans'
  | 'payment.service_supplier.shop_customer_id'
> &
  typeof atoneSettlementCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Np.configのサービスパラメータ - atone翌月後払い認証
 */
type NpAtoneAuthServiceInputType = DeepOmit<AtoneAuthServiceInputType, 'is_legacy'> &
  typeof atoneAuthCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Np.configのサービスパラメータ - atoneつど後払い決済
 */
type NpAtoneLiteSettlementServiceInputType = DeepOmit<
  AtoneLiteSettlementServiceInputType,
  'payment.description_trans' | 'payment.service_supplier.shop_customer_id'
> &
  typeof atoneLiteSettlementCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Np.configのサービスパラメータ - atoneつど後払い認証
 */
type NpAtoneLiteAuthServiceInputType = AtoneLiteAuthServiceInputType &
  typeof atoneLiteAuthCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Np.configのサービスパラメータ - Np後払い決済
 */
type NpNpAtobaraiSettlementInputType = NpAtobaraiSettlementServiceInputType &
  typeof npAtobaraiSettlementCallbackDefinition.schema._input &
  AdditionalSpaceParam;

/**
 * Np.configのサービスパラメータ
 *
 * サービスタイプが追加になったらその都度。追加すること。
 */
type ServiceParameter =
  | NpAtoneSettlementServiceInputType
  | NpAtoneAuthServiceInputType
  | NpAtoneLiteSettlementServiceInputType
  | NpAtoneLiteAuthServiceInputType
  | NpNpAtobaraiSettlementInputType;

// 型定義的には正しくともコールバック型は複雑なintersection型になるため、型推論もIntelliSenseも効かないはず。
// おそらくほとんどの実装者はany型を充てないと実装できない。
// この責任は設計の考慮不足によるもので、今からではどうしようもない。
// サービスによってコールバックの型もありようも変わるのに、serviceごとに定義しない作りになってることが間違い。
// (実装当初はコールバックはサービス内容に関わらず互換と考えていたためこのようなことになってる。)
// そのため、現在はserviceの中にコールバック定義が可能になっており、そちらでは型的に良い感じに実装できる。
// このレイヤーで捕捉しなければならないのは、serviceに依存しないエラーが発生した場合のエラーコールバックのみで、
// 他のコールバックについてはサービスの階層で実装すべき。
// TODO: このレイヤーのコールバック型定義はany型などにしてしまうことを検討する。
type CommonCallbacks = typeof atoneSettlementCallbackDefinition.schema._input &
  typeof atoneAuthCallbackDefinition.schema._input &
  typeof atoneLiteSettlementCallbackDefinition.schema._input &
  typeof atoneLiteAuthCallbackDefinition.schema._input &
  typeof npAtobaraiSettlementCallbackDefinition.schema._input;

type NpConfigCommonParam = {
  /**
   * モーダルのz-indexを指定する。
   *
   * servicesごとに設定することも可能だがあまり意味はない。
   */
  z_index?: number;
  /**
   * pre_tokenをここに設定した場合、それぞれのserviceに値がマージされます。
   *
   * @deprecated pre_tokenはサービスに紐づくもので共通項目というわけではないのでservicesの階層に定義することが望ましい。
   */
  pre_token?: string;
  /**
   * @deprecated pub_keyはサービスに紐づくもので共通項目というわけではないのでservicesの階層に定義することが望ましい。
   */
  pub_key?: string;
  /**
   * @deprecated terminal_idはサービスに紐づくもので共通項目というわけではないのでservicesの階層に定義することが望ましい。
   */
  terminal_id?: string;
} & AdditionalSpaceParam;

type NpConfigParameter = {
  services: ServiceParameter[];
} & NpConfigCommonParam &
  CommonCallbacks;

const callbackDefinitions = [
  atoneLiteAuthCallbackDefinition,
  atoneSettlementCallbackDefinition,
  atoneAuthCallbackDefinition,
  atoneLiteSettlementCallbackDefinition,
  npAtobaraiSettlementCallbackDefinition,
];

/**
 * とのタイプで初期化されてるか。
 *
 * このタイプは今後増やさない。旧atoneのパラメータで起動したのかそうでないのか、判別するためのもの。
 * 旧Atoneのパラメータで起動可能なのは翌月後払いの決済モーダルのみ。認証モーダルには対応しない。できない。
 */
const CONFIG_TYPE = {
  /**
   * コンフィグ未実行状態。
   *
   * この状態ではstartできない。config以外何もできない。
   */
  NONE: 0,

  /**
   * 統合インターフェイスのconfigで初期化されたことを示す。
   */
  BERLIN: 1,

  /**
   * 旧atoneのコンフィグで初期化されたことを示す。
   */
  LEGACY_ATONE: 2,
} as const;

/**
 * Np.configで渡したconfigのタイプを保持する。
 *
 * configTypeは初回のNp.config実行時に一度だけ、設定される。
 * 例えば旧Atoneパラメータで初期化した後、統合インターフェイスのパラメータで初期化し直すとか、その逆もできない。
 * 未確定、統合インターフェイスBerlin、旧AtoneConfig形式のどれかの値を取る。
 */
let configType = CONFIG_TYPE.NONE as (typeof CONFIG_TYPE)[keyof typeof CONFIG_TYPE];

/**
 * 入力値から指定したプロパティを除去した値を返します。
 * @param value
 * @param keys
 * @returns
 */
const omit = <T extends Record<string | number | symbol, unknown>, U extends keyof T>(
  value: T,
  keys: U[],
): Omit<T, U> => {
  if (!value || !['object', 'function'].includes(typeof value)) throw new Error('不正な引数');
  const clone = { ...value };
  keys.forEach((k) => delete clone[k]);
  return { ...clone };
};

const isRecord = (value: unknown): value is Record<string | number | symbol, unknown> =>
  value !== null && value !== undefined && (typeof value === 'object' || typeof value === 'function');

const RawNp = {
  config(param: NpConfigParameter) {
    // 通常のモード
    try {
      configType = CONFIG_TYPE.BERLIN;
      const { services: rawServices, ...rest } = param;
      /** servicesの外に配置されてるコールバック */
      const commonCallbacks = Object.fromEntries(
        // 行儀悪いけど `is_legacy` を決め打ちで取り除く。
        Object.entries(rest).filter(([k, v]) => typeof v === 'function' && k !== 'is_legacy'),
      ) as CommonCallbacks;
      /** servicesの外に配置されてるパラメータ */
      const commonParams = Object.fromEntries(
        // 行儀悪いけど `is_legacy` を決め打ちで取り除く。
        Object.entries(rest).filter(([k, v]) => typeof v !== 'function' && k !== 'is_legacy'),
      ) as NpConfigCommonParam;

      // service_typeとmodal_modeに不正値がないかチェックする。
      z.object({
        services: z.array(
          z.union(
            callbackDefinitions.map((v) => v.schema) as unknown as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]],
          ),
        ),
      }).parse(param); // ここは例外が発生しうる。

      const services = rawServices.map((s, i) => {
        /** モーダルモードが設定されているか */
        const hasModalMode = 'modal_mode' in s;
        // コールバックはコールバック用スキーマでパースする。
        const callbackSchema =
          callbackDefinitions.find((def) => def.classifier.safeParse({ modal_mode: '01', ...s }).success)?.schema ??
          z.object({});
        const callbacks = {
          // servicesの層を追加。zodはエラーがどこで発生したのかobjectの構造をトレースするため、最終的なデータと同じパスでコールバックを扱う必要がある。
          services: [
            // 空オブジェクトの挿入。zodエラーが発生した時に配列要素のどこでエラーが発生したのか、pathが正確に取れるように。
            ...Array.from({ length: i }).fill({}),
            // コールバック
            Object.fromEntries(
              Object.entries({
                // 共通レイヤーのコールバックとserviceレイヤーのコールバックを合成。
                ...commonCallbacks,
                ...Object.fromEntries(
                  Object.entries(s).flatMap(([k, v]) =>
                    typeof v === 'function' && k !== 'is_legacy' ? [[k, v] as const] : [],
                  ),
                ),
              }).map(([k, fn]) => {
                // サービスにmodal_modeが指定されてる場合、コールバックをそのまま
                if (hasModalMode) return [k, fn];
                const wrappedFn: typeof fn = (callbackParam, ...r) =>
                  // modal_modeが指定されていない場合、コールバックの第一引数からmodal_modeを除去する。
                  (fn as (...v: unknown[]) => unknown)(
                    isRecord(callbackParam) ? omit(callbackParam, ['modal_mode']) : param,
                    ...r,
                  );
                return [k, wrappedFn];
              }),
            ),
          ],
        };
        const parsedCurrentServiceCallbacks = z
          .object({
            services: z.tuple([...(Array.from({ length: i }).fill(z.unknown()) as []), callbackSchema]),
          })
          .parse(callbacks).services[i];

        const currentServiceParams = Object.fromEntries(
          Object.entries(s).filter(([k, v]) => typeof v !== 'function' && k !== 'is_legacy'),
        );

        return {
          // 共通パラメータと共通コールバック
          ...commonParams,
          // サービス固有のパラメータとコールバック
          ...currentServiceParams,
          ...parsedCurrentServiceCallbacks,
        };
      });
      ModalDriver.init(services as ({ service_type: string; modal_mode?: string } & { [K in string]: unknown })[]);
    } catch (e) {
      const errorCallback =
        param.error ??
        // ここで取り出すコールバックはzodでパースしないものが欲しいため、paramから直接取得する。zod通すと引数に制約がついてしまうからというのが主たる理由ではある。
        // 制約のため、下手すると引数チェックで落とされて呼べないケースが出てくるかもしれないため。まぁ現状の想定では平気なはずだと思うけど。実装は意図的だよってこと。
        param.services.flatMap((s) => (s.error ? [s.error] : [])).at(0) ??
        ((err: unknown) => {
          console.error(err);
        });
      if (e && e instanceof z.ZodError) {
        e.issues.forEach((i) => {
          const serviceIndex = i.path[0] === 'services' && typeof i.path[1] === 'number' ? i.path[1] : undefined;
          const service =
            serviceIndex !== undefined
              ? {
                  service_type: param?.services?.[serviceIndex]?.service_type ?? '01',
                  modal_mode: param?.services?.[serviceIndex]?.modal_mode ?? '01',
                }
              : undefined;
          const errorCallbackParam = {
            ...service,
            name: ERROR_TYPE.REQUEST_EXCEPTION.name,
            message: fromZodIssue(i).toString(),
            errors: [],
          };
          if (
            i.path[0] === 'services' &&
            typeof i.path[1] === 'number' &&
            typeof param.services[i.path[1]]?.error === 'function'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (param.services[i.path[1]].error ?? (() => {}))(errorCallbackParam as unknown as any);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errorCallback(errorCallbackParam as unknown as any);
        });
      } else {
        // ZodErrorではない（バリデーションエラーではない）場合、はserviceに紐づかないため、service_typeやmodal_modeはない。
        errorCallback(
          e && e instanceof Error
            ? {
                name: e.name,
                message: e.message,
                errors: [],
              }
            : {
                name: ERROR_TYPE.REQUEST_EXCEPTION.name,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                message: `${e as any}`,
                errors: [],
              },
        );
      }
    }
  },
  start(param: Pick<ServiceParameter, 'service_type' | 'modal_mode'> | ServiceType | undefined) {
    // paramを整形
    const formattedParam: { service_type: string; modal_mode?: string } = { service_type: '01' };
    if (typeof param === 'string') {
      formattedParam.service_type = param;
    } else if (typeof param === 'object') {
      Object.assign(formattedParam, pick(param, 'service_type', 'modal_mode'));
    }
    switch (configType) {
      case CONFIG_TYPE.LEGACY_ATONE: {
        AtoneModule.start();
        break;
      }
      case CONFIG_TYPE.BERLIN: {
        ModalDriver.start({ modal_mode: '01', ...formattedParam });
        break;
      }
      case CONFIG_TYPE.NONE: {
        break;
      }
      default: {
        const never: never = configType;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`[ケース網羅不正] \`configType === ${never}\` のケースが考慮されていません。`);
      }
    }
  },
};

/**
 * 生のconfigパラメータを保持しておく。
 *
 * これはNp.mergeなどで使用する。
 */
let rawConfigParam: NpConfigParameter | undefined;

/**
 * 生のconfigパラメータのアレーを保持しておく
 *
 * これはNp.startで使用する。
 */
const rawConfigParamArr: OperationParam[] = [];

/**
 *
 */
const Np = {
  /**
   * 統合インターフェイスと旧Atone決済のパラメータを受け付けるconfigコマンドです。
   *
   * ※重要 旧AtoneRegisterのパラメータは受け付けません。
   *
   * 旧Atoneの決済モーダルと旧Atoneの認証モーダルについて、各コマンドで適切にそれぞれのサービスの差異を見極めることができるスキーマ定義ではないため、
   * Np.xxxx系命令では旧認証モーダルの実行引数は受け付けないようになっています。
   * 認証モーダルで旧パラメータを受け付けたい場合、引き続き、 `AtoneRegister.config` などの旧コマンドを使用して下さい。
   *
   * 統合インターフェイスでは旧Atone、旧AtoneRegisterに対するバックポートインターフェイスがあるため、旧パラメータで使用したいのであれば、そちら側で使用すべきです。
   * NpモジュールがAtoneRegisterモジュールのパラメータを受け付けられないのに、Atoneモジュールのパラメータだけ受け付けられる作りは設計ミスですが、
   * すでに公開しているインターフェイスであり変更がむずかしいです。
   * @param param
   * @returns
   */
  config: (param: NpConfigParameter, callback?: () => void): void => {
    // 生なパラメータを保存する。
    rawConfigParamArr.push({
      modal_type: 'Np',
      name: 'config',
      raw_data: JSON.stringify(param),
    } as OperationParam);

    // 初回実行時にconfigTypeを決定する。以降変更はできないものとする。
    if (configType === CONFIG_TYPE.NONE) {
      if (!Array.isArray(param.services)) {
        // servicesが配列として存在しないのであれば旧Atoneパラメータとみなす。
        configType = CONFIG_TYPE.LEGACY_ATONE;
      } else {
        configType = CONFIG_TYPE.BERLIN;
      }
    }
    if (configType === CONFIG_TYPE.LEGACY_ATONE) {
      AtoneModule.config(param as unknown as AtoneConfig, callback);
      return;
    }

    configType = CONFIG_TYPE.BERLIN;
    RawNp.config(param);
    rawConfigParam = param;
    if (callback) {
      console.warn('第二引数は非推奨です。');
      callback();
    }
  },

  /**
   * 指定したサービスのモーダルを起動します。
   *
   * 旧atoneで初期化した場合はパラメータは必要ありません。
   * @param param
   */
  start(param: Pick<ServiceParameter, 'service_type' | 'modal_mode'>) {
    // 生なパラメータを保存する。
    rawConfigParamArr.push({
      modal_type: 'Np',
      name: 'start',
      raw_data: JSON.stringify(param),
    } as OperationParam);
    // パラメータをログするAPIを呼ぶ。
    callLogPostAPINotRaiseErr(
      JSON.stringify({
        session_id: useSessionId(),
        referer: document.location.href || '',
        operations: rawConfigParamArr,
      }),
    );

    switch (configType) {
      case CONFIG_TYPE.NONE: {
        RawNp.start(param);
        throw new Error('Np.configが呼ばれていません。');
      }
      case CONFIG_TYPE.BERLIN: {
        RawNp.start(param);
        break;
      }
      case CONFIG_TYPE.LEGACY_ATONE: {
        AtoneModule.start();
        break;
      }
      default: {
        // このerrorがthrowされるならモーダルドライバー側の実装に問題があるはず。
        throw new Error('CONFIG_TYPEの異常。');
      }
    }
  },

  /**
   * 昨日は生きていませんが通常使用するべきではありません。
   *
   * 主な理由は、
   *
   * レガシーインターフェイスに対応するために用意されています。
   * 通常、パラメータの修正が必要な場合、再度、configを実行すれば良いです。
   * 旧Atoneではmergeは浅いマージを行いましたが、統合インターフェイスでは、
   *
   * - services以外を浅いマージする
   * - service_typeとmodal_modeをキーとしてservicesの各要素を、浅いマージする
   *
   * という2段構えでデータをマージします。
   * @deprecated
   */
  merge(partialConfig?: Partial<NpConfigParameter>) {
    // 生なパラメータを保存する。
    rawConfigParamArr.push({
      modal_type: 'Np',
      name: 'merge',
      raw_data: JSON.stringify(partialConfig),
    } as OperationParam);

    if (!partialConfig) return;
    switch (configType) {
      case CONFIG_TYPE.NONE: {
        throw new Error('Np.configが呼ばれていません。');
      }
      case CONFIG_TYPE.BERLIN: {
        if (!rawConfigParam) return;
        const { services: inputServices, ...inputBase } = partialConfig;
        const { services: currentServices, ...currentBase } = rawConfigParam;
        /** partialConfigで既存のサービスのパラメータを更新したもの */
        const updatedServices = currentServices.map((service) => {
          const inputService = inputServices?.find(
            (s) => s.service_type === service.service_type && (s.modal_mode ?? '01') === (service.modal_mode ?? '01'),
          );
          return inputService
            ? // 同じ種別のserviceがあったら、そのサービスのレベルで浅いマージを行う。
              ({ ...service, ...inputService } as typeof service)
            : service;
        });
        /** partialConfigで追加されたサービスServiceを検出 */
        const appendedServices =
          inputServices?.filter(
            (inputService) =>
              !currentServices.find(
                (currentService) =>
                  inputService.service_type === currentService.service_type &&
                  (inputService.modal_mode ?? '01') === (currentService.modal_mode ?? '01'),
              ),
          ) ?? [];
        /** マージされたconfigパラメータ。 */
        const npConfigParameter = {
          // services以外を浅いマージ
          ...currentBase,
          ...inputBase,
          // 更新されたサービスと追加されたサービスからなるservicesをセット
          services: [...updatedServices, ...appendedServices],
        };
        Np.config(npConfigParameter);
        break;
      }
      case CONFIG_TYPE.LEGACY_ATONE: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        AtoneModule.merge(partialConfig as any);
        break;
      }
      default: {
        // このerrorがthrowされるならモーダルドライバー側の実装に問題があるはず。
        throw new Error('CONFIG_TYPEの異常。');
      }
    }
  },

  /**
   * 何もしません。
   *
   * レガシーインターフェイスに対応するために用意されています。
   * config情報を更新し、そのデータがモーダル側に同期されるタイミングは、モーダルが非アクティブである状態のはず。
   * そこをあえてショップ実装者がコントロールする必要はない。
   *
   * 何もしないこのメソッドが存在する理由は、atoneとの互換性のためです。
   * @deprecated
   */
  sync() {},

  /**
   * 通常使用するべきではありません。
   *
   * 猜疑に設定したパラメータを返します。最後にモーダルへ送られたパラメータではないことに注意してください。
   * 実行が保留されている場合、モーダルに設定されたパラメータとの差異が発生する場合があります。
   *
   * このメソッドはレガシーAtoneインターフェイスに対応するために用意されています。
   * mergeを使用しないのであれば、configに渡した引数は会員サイト側で保持することは難しくないはずなので
   * @deprecated
   */
  properties() {
    switch (configType) {
      case CONFIG_TYPE.BERLIN: {
        return rawConfigParam;
      }
      case CONFIG_TYPE.LEGACY_ATONE: {
        return AtoneModule.properties();
      }
      case CONFIG_TYPE.NONE: {
        throw new Error('Np.configが呼ばれていません。');
      }
      default: {
        const never: never = configType;
        throw new Error(`[ケース網羅性違反] \`configType === ${never}\` のケースが漏れています。`); // eslint-disable-line @typescript-eslint/restrict-template-expressions
      }
    }
  },

  /**
   * 通常使用するべきではありません。
   *
   * モーダルのロードが完了し、configやstartが呼べるかどうかをチェックするために用意されたインターフェイスですが、
   * ロードが完了していなくてもconfig start等のコマンド受付ができるように作られているため、このメソッドは常にtrueを返却するように再設計されています。
   *
   * @deprecated
   */
  loaded(): true {
    return true;
  },

  /**
   * 使用できません。
   *
   * 旧atoneに存在するメソッドで、内部クラスを直接返すメソッドですが、根本的な実装が異なるため値を返却することができません。
   * このメソッドは実装できないため、依存しているページは修正が必要です。
   * どうしても必要な場合、使用している会員様がどういう使い方をしているか調べて、代わりとなる何かを返すように改修する必要があるかも。
   *
   * @deprecated
   */
  instance(): void {
    throw new Error('Np.instance()は廃止されました。使用できません。');
  },
};

export default Np;
