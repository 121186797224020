import { uuid } from '../utils';

/**
 * fraudAlertで使用するsessionIdです。
 *
 * ランダムな文字列で、アプリケーションロード時に生成され、リロードなどでアプリケーションを実行し直さない限り固定です。
 */
const sessionId = uuid();

/**
 * fraudAlertのsessionIdを提供します。
 *
 * @returns アプリケーションの開始に対して一意となるUUID形式の文字列です。アプリケーションのどこから、いつ呼び出しても同じIDを返します。
 */
export function useSessionId() {
  return sessionId;
}
